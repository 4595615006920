var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "card",
        { attrs: { title: _vm.$t("models.region.selves") } },
        [
          _c("grid-view", {
            ref: "grid",
            attrs: {
              headers: _vm.headers,
              model: "region",
              endpoint: "/region",
            },
            scopedSlots: _vm._u([
              {
                key: "item._actions",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", small: "" },
                        on: {
                          click: function ($event) {
                            return _vm.viewModel(item)
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { small: "" } }, [
                          _vm._v("mdi-eye"),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", small: "" },
                        on: {
                          click: function ($event) {
                            return _vm.editModel(item)
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { small: "" } }, [
                          _vm._v("mdi-pencil"),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", small: "" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteModel(item)
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { "x-small": "" } }, [
                          _vm._v("fa fa-trash"),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "item.name",
                fn: function (ref) {
                  var item = ref.item
                  return [_vm._v(" " + _vm._s(_vm.translate(item.name)) + " ")]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.viewDialog,
            callback: function ($$v) {
              _vm.viewDialog = $$v
            },
            expression: "viewDialog",
          },
        },
        [
          _vm.model
            ? _c(
                "v-card",
                [
                  _c("v-card-title", { attrs: { "primary-title": "" } }, [
                    _c("div", [
                      _c("h3", { staticClass: "headline mb-0" }, [
                        _vm._v(
                          " " + _vm._s(_vm.translate(_vm.model.name)) + " "
                        ),
                      ]),
                      _c("div", [_vm._v(_vm._s(_vm.model))]),
                    ]),
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.editModel(_vm.model)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("actions.edit")))]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.viewDialog = false
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("actions.close")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.editDialog,
            callback: function ($$v) {
              _vm.editDialog = $$v
            },
            expression: "editDialog",
          },
        },
        [
          _vm.editDialog
            ? _c("Form", {
                attrs: { regionId: _vm.editedModelId },
                on: { save: _vm.onDialogSave },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }